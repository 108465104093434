var render, staticRenderFns
import script from "./pendingList.vue?vue&type=script&lang=js"
export * from "./pendingList.vue?vue&type=script&lang=js"
import style0 from "./pendingList.vue?vue&type=style&index=0&id=5c66408d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c66408d",
  null
  
)

export default component.exports