<script>
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction, delAction } from '@/command/netTool'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import DrawerForm from '../../../components/DrawerForm'

export default {
  name: 'pendingList',
  data() {
    return {
      ...api.command.getState(),
      tableData: [],
    }
  },
  mounted() {
    this.getDataInit()
  },
  methods: {
    getDataInit() {
      api.command.getList.call(this, {
        url: '/farmSalesUser/page',
        current: 1,
        paramsValue: {
          status: '0',
        },
      })
    },
    getHeader() {
      return [
        {
          name: '姓名',
          type: 'input',
          key: 'name',
        },
        {
          name: '手机号',
          type: 'input',
          key: 'phone',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'name',
          title: '姓名',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item-copy">
                {records.shopImg ? <img src={records.shopImg.split(',')[0]} /> : ''}
                <div>{text}</div>
              </div>
            )
          },
        },
        {
          dataIndex: 'phone',
          title: '手机号',
          align: 'left',
        },
        {
          dataIndex: 'parentName',
          title: '邀请方',
          align: 'left',
          customRender: function (text, records) {
            return <div>{records.parentId === '0' ? '平台' : text}</div>
          },
        },
        {
          dataIndex: 'totalOrderAmount',
          title: '累计销售额',
          align: 'left',
          sorter: (a, b) => a.totalOrderAmount.length - b.totalOrderAmount.length,
          customRender: function (text, records) {
            return <div>{text ? (+text || 0).toFixed(2) : '-'}</div>
          },
        },
        {
          dataIndex: 'totalCommission',
          title: '累计佣金',
          align: 'left',
          sorter: (a, b) => a.totalCommission.length - b.totalCommission.length,
          customRender: function (text, records) {
            return <div>{text ? (+text || 0).toFixed(2) : '-'}</div>
          },
        },
        {
          dataIndex: 'belowCount',
          title: '下级数量',
          align: 'left',
          sorter: (a, b) => a.belowCount.length - b.belowCount.length,
          customRender: function (text, records) {
            return <div>{text ? text : '-'}</div>
          },
        },
        {
          dataIndex: 'createTime',
          title: '加入时间',
          align: 'left',
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '详情',
                onClick: () => {
                  getAction(`/farmSalesUser/detail?id=${records.id}`).then((result) => {
                    if (result.code == 200) {
                      this.handleEditPoint(result.data)
                    } else {
                      this.$message.error(result.msg)
                    }
                  })
                },
              },
              {
                name: '通过',
                onClick: () => {
                  putAction(`/farmSalesUser/approved?id=${records.id}`).then((result) => {
                    if (result.code == 200) {
                      this.getDataInit()
                      this.$message.success('审核通过')
                      this.$emit('init')
                    } else {
                      this.$message.error(result.msg)
                    }
                  })
                },
              },
              {
                name: '拒绝',
                onClick: () => {
                  this.handlePropup(records)
                },
              },
            ]
          },
        },
      ]
    },
    handlePropup(records) {
      apiTool.showDrawer({
        title: '拒绝',
        width: '600px',
        form: [
          {
            name: '拒绝理由',
            type: 'textArea',
            key: 'content',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
        ],
        success: ({ data, setHidden }) => {
          if (data.content) {
            putAction(`/farmSalesUser/auditReject`, { id: records.id, reason: data.content }).then((result) => {
              if (result.code == 200) {
                this.getDataInit()
                setHidden()
                this.$message.success('拒绝成功')
                this.$emit('init')
              } else {
                this.$message.error(result.msg)
              }
            })
          } else {
            this.$message.warning('请填写拒绝理由！')
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },

    getDrawerForm(records) {
      const form = [
        {
          display: true,
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '姓名',
                  value: records.name,
                  display: true,
                },
                {
                  name: '邀请方',
                  value: records.parentId === '0' ? '平台' : records.parentName,
                  display: true,
                },
                {
                  name: '手机号',
                  value: records.phone,
                  display: true,
                },
                {
                  name: '加入时间',
                  value: records.createTime,
                  display: true,
                },
                {
                  name: '身份证',
                  value: records.idCard,
                  display: true,
                },
                {
                  name: '邀请码',
                  value: records.shareCode,
                  display: true,
                },
              ].filter((e) => e.display),
            },
            {
              type: 'imageGroup',
              value: [records.idCardBackImg, records.idCardFaceImg],
            },
          ],
        },
        {
          title: '业绩概况',
          display: true,
          form: [
            {
              type: 'textTotle',
              title: '累计销售额(元)',
              cols: 12,
              value: (+records.totalOrderAmount || 0).toFixed(2),
            },
            {
              type: 'textTotle',
              title: '累计订单数',
              cols: 12,
              value: records.totalOrderCount || 0,
            },
          ],
        },
        {
          title: '佣金统计',
          display: true,
          form: [
            {
              type: 'textTotle',
              title: '累计佣金(元)',
              cols: 8,
              value: (+records.totalCommission || 0).toFixed(2),
            },
            {
              type: 'textTotle',
              title: '商品佣金(元)',
              cols: 8,
              value: (+records.orderCommission || 0).toFixed(2),
            },
            {
              type: 'textTotle',
              title: '邀请佣金(元)',
              cols: 8,
              value: (+records.inviteCommission || 0).toFixed(2),
            },
          ],
        },
        {
          title: '推广统计',
          display: true,
          form: [
            {
              type: 'textTotle',
              title: '推广收益(元)',
              cols: 12,
              value: (+records.extendsOrderCommission || 0).toFixed(2),
            },
            {
              type: 'textTotle',
              title: '推广订单',
              cols: 12,
              value: records.extendsOrderCount || 0,
            },
          ],
        },
      ].filter((e) => e.display)
      return form
    },
    handleEditPoint(pontData = {}) {
      let dataObj = this.getDrawerForm(pontData)
      apiTool.showDrawer({
        title: '基本信息',
        width: '680px',
        view: DrawerForm,
        viewProps: {
          data: dataObj,
        },
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        // button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
/deep/.ant-popover-message-title {
  max-width: 200px !important;
}
</style>
